@import "styles/theme.scss";
.aside {
  margin: 12px 0 12px 12px;

  width: 100%;

  max-width: 24px;

  &[data-open='true'] {
    max-width: 300px;

    .button {
      &Container {
        border-top-left-radius: $border-radius-s;
        border-bottom-left-radius: $border-radius-s;
      }
    }
  }

  &[data-open='false'] {
    .wrapper {
      padding: 0;
    }

    .button {
      &Container {
        border-top-right-radius: $border-radius-s;
        border-bottom-right-radius: $border-radius-s;
      }

      &Icon {
        transform: rotate(180deg);
      }
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;

  overflow-y: auto;
}

.title {
  font-size: $font-size-l;
}

.icon {
  padding-bottom: 12px;
}

.separator {
  margin: 12px 0;

  background-color: $grey-extra-light-color;

  border-width: 0;
  height: 1px;
}

.item {
  & + & {
    margin-top: 20px;
  }
}

.navigate {
  display: block;

  padding: 2px 4px;

  border-radius: $border-radius-s;
  font-weight: $font-weight-bold;

  cursor: pointer;

  &[data-active='true'] {
    background-color: $blue-extra-light-color;
  }
}

.information {
  margin-top: auto;

  &Text {
    margin: 12px 0;

    font-size: $font-size-s;
  }

  &Button {
    width: 100%;
  }
}

.button {
  &Container {
    position: absolute;

    display: flex;
    align-items: center;

    top: 50%;
    right: 0;

    height: 64px;

    background-color: $accent-color;

    box-shadow: $box-shadow-main;

    transform: translate(0, -50%);
  }

  &Icon {
    display: flex;
    flex-shrink: 0;
  }
}
